import UtilService from '@/service/UtilService.js';


class UtilWorkOrder {

    static Role = {
        Admin: 'ADMIN',
        Manager: 'MANAGER',
        Worker: 'WORKER',
    }

    static months = ["Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"]

    static prepareWorkLocationForTree(workLocations, positionGroups) {

        let workLocationsCopy = JSON.parse(JSON.stringify(workLocations));
        UtilService.recalculateLocationFullIndex(workLocationsCopy);
        let locationsTotal = UtilService.deepClone(UtilService.locationsTotal);
        this.addPositionGroupToWL(workLocationsCopy, positionGroups)

        return {
            data: workLocationsCopy,
            total: locationsTotal
        }
        
    }

    static addPositionGroupToWL(workLocations, positionGroups) {
        let explore = [...workLocations];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current.children);

            if (current.position_group_id)
                current.position_group_obj = positionGroups.find(x=> x.group.id == current.position_group_id).group
            current.test = 1;
        }

    }

    static prepareWorkPositionsForTree(workPositions) {

        let workPositionsCopy = JSON.parse(JSON.stringify(workPositions));
        UtilService.recalculateLocationFullIndex(workPositionsCopy);

        return {
            data: workPositionsCopy,
        }
        
    }

    static setDeleteRows(treeData, rows, state) {
        let idList = rows.map(x => x.id);

        let explore = [...treeData];
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();
            explore.push.apply(explore, current["children"]);
            if (idList.includes(current.id))
                current.deleted = state;
        }

        return treeData
    }

}

export default UtilWorkOrder;
