<template>
    <div class="container mb-5">

        <modal-position-type ref="modalTypes" v-model="workPosionsCopy" :workOrderID=workOrderID inputId="modalTypes"/>
        <modal-import-work-positions :thisModal="modalImportWorkPositions" modalId="modalImportWorkPositions" title="Pozicije" :importFunction="importWorkPositions" ref="modalImportWorkPositions" />

        <div class="title-header">
            <div class="row px-3">
                <div class="col-sm-6">
                    <h2>
                        <span v-if="workOrder" class="badge bg-primary">{{workOrder.code}}</span>
                        Radne pozicije
                    </h2>
                </div>

                <div v-if="user.role === 'ADMIN'" class="col-sm-6">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="d-grid gap-2">
                                <!-- <button type="button" class="btn-header btn-block btn-primary btn-lg"><i class="fas fa-file-excel fa-lg me-1" /> Export Excel</button> -->
                                <button v-if="editMode" v-on:click="saveEdit" class="btn btn-success"><i class="fas fa-check fa-lg me-1" /> Sačuvaj izmene</button>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-grid gap-2">
                                <button v-show="!editMode" type="button" class="btn-header btn-block btn-primary btn-lg" v-on:click="enableEditMode"><i class="fas fa-edit fa-lg me-1" /> izmena radnih pozicija</button>
                                <!-- <button :disabled="editMode" type="button" class="btn-header btn-block btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#modalTypes" v-on:click="openTypesModal()"><i class="fas fa-object-group fa-lg me-1" /> Uredi tipove</button> -->

                                <button v-show="editMode" v-on:click="discartEdit" class="btn btn-danger"><i class="fas fa-times fa-lg me-1" /> Poništi izmene</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <!-- :columns='gridColumns' -->
        <template>
            <ejs-treegrid
                ref='positionsTree'
                class="positions-table"
                :dataSource='workPositionsTree'
                childMapping='children'
                :allowRowDragAndDrop='false'
                :allowReordering='true'
                :allowFiltering='true'
                :allowSorting='true'
                :filterSettings='filterSettings'
                :showColumnMenu='true'
                :allowResizing='true'
                :selectionSettings="selection"
                :allowGrouping='true'
                :treeColumnIndex='1'
                :editSettings='editSettings'

                :allowPaging='false' 
                :pageSettings='pageSettings'
                :toolbar='toolbar'
                :toolbarClick='toolbarClickHandler'
                :rowDrop="rowDrop" 
                :queryCellInfo='customiseCell'
                :actionFailure="actionFailure"


                :actionComplete='actionCompleteEvent'
                >
                <e-columns>
                    <e-column field='id' headerText='id' isPrimaryKey='true' :allowEditing='false' width='100' :visible='false'></e-column>
                    <e-column field='fullIndex' headerText='Šifra' :allowEditing='false' width='100'></e-column>
                    <!-- <e-column field='name' headerText='Naziv' width='188' textAlign='Left'></e-column> -->
                    <e-column field='position_unit' headerText='Posao' textAlign='Left' editType='dropdownedit' :edit="positionUnitsParams" :valueAccessor='positionUnitsAccessor'></e-column>
                    <e-column field='minutes_per_unit' headerText='Minuta po poslu' textAlign='Left' editType='numericedit' :edit="minutesPerUnitParams"></e-column>
                    <e-column headerText='Komande' width='140' :commands='commands' :allowEditing='false'></e-column>

                </e-columns>
            </ejs-treegrid>
        </template>
        
    </div>
</template>

<script>
import ModalPositionType from '@/components/modals/ModalPositionType.vue';
import ModalImportWorkPositions from '@/components/modals/work-positions/ModalImportWorkPositions';
import {mapState} from 'vuex'
import UtilService from '@/service/UtilService';
import { Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn } from "@syncfusion/ej2-vue-treegrid";    
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { NumericTextBox } from "@syncfusion/ej2-inputs";
import WorkPositionService from '@/service/WorkPositionService';
import { Modal } from 'bootstrap'
import UtilWorkOrder from '@/service/UtilWorkOrder';


require('@/assets/vue-nestable.css')
require('@/assets/tree-view.css')

export default {
    components: { 
        ModalPositionType,
        ModalImportWorkPositions,
    },

    provide: {
        treegrid: [ Filter, Edit, Sort, Reorder, Resize, RowDD, Toolbar, Page, ColumnMenu, CommandColumn ]
    },

    data () {
        return {
            editMode: false,

            selection: { type: 'Multiple' },
            filterSettings: { type: 'Excel' },
            editSettings: { allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Row', newRowPosition: 'Child' },
            pageSettings: { pageCount: 5, pageSize: 50 },


            toolbarEdit: [
                { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: '_gridcontrol_add' },
                { text: 'Uvezi', tooltipText: 'Uvezi iz drugog naloga', prefixIcon: 'e-icons e-undo', id: 'importPositions' },
                { text: 'Izbriši', tooltipText: 'Izbriši', prefixIcon: 'e-delete', id: 'deleteLocation' },
                { text: 'Ažuriraj', tooltipText: 'Ažuriraj', prefixIcon: 'e-update', id: '_gridcontrol_update' }, 
                { text: 'Otakži', tooltipText: 'Otakži', prefixIcon: 'e-cancel', id: '_gridcontrol_cancel' }],
            commandsEdit: [
                { type: 'Dodaj', buttonOption: { click:this.addRowBelow, iconCss: ' e-icons e-add', cssClass: 'e-flat c-column-hide-edit' } },
                { type: 'DodajUnutar', buttonOption: { click:this.addRowChild, iconCss: ' e-icons e-add-above', cssClass: 'e-flat c-column-hide-edit' } },
                { type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat c-column-hide-edit' } },
                { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat c-column-hide-edit' } },
                { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
                { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }],


            gridColumns: [
                {'field':'id', 'headerText':'id', 'isPrimaryKey':true, 'allowEditing':false, 'width':100, 'visible':false},
                {'field':'fullIndex', 'headerText':'Šifra', 'allowEditing':false, 'width':100},
                {'field':'name', 'headerText':'Naziv', 'width':'188', 'textAlign':'Left'},
                {'field':'position_unit', 'headerText':'Posao', 'textAlign':'Left', 'editType':'dropdownedit', 'edit':this.positionUnitsParams, 'valueAccessor':this.positionUnitsAccessor},
                {'field':'minutes_per_unit', 'headerText':'Minuta po poslu', 'textAlign':'Left', 'editType':'numericedit', 'edit':this.minutesPerUnitParams},
                {'headerText':'Komande', 'width':'140', 'commands':this.commands, 'allowEditing':false},
            ],

            positionUnitElem: undefined,
            positionUnitObj: undefined,
            positionUnitsData: [],

            positionUnitsParams: {
                create: () => {
                    this.positionUnitElem = document.createElement("input");
                    return this.positionUnitElem;
                },
                read: () => {
                    return this.positionUnitObj.value;
                },
                destroy: () => {
                    this.positionUnitObj.destroy();
                },
                write: (event) => {
                    console.log("write");
                    console.log(event);
                    this.positionUnitObj = new DropDownList({
                        allowFiltering: true,
                        dataSource: this.positionUnitsData,
                        fields: { value: "positionUnitId", text: "positionUnitName", data: "positionUnitObj" },
                        floatLabelType: "Never",
                        placeholder: "Poslovna jedinica",
                        change: () => {
                            console.log(this.positionUnitObj.value);
                            if (this.positionUnitObj.value > 0) {
                                let obj = this.positionUnits.find(x=>x.id === this.positionUnitObj.value);
                                this.MPUObj.value = obj.minutes_per_unit;
                            } else {
                                this.MPUObj.value = null;
                            }

                        }
                    });

                    console.log(this.positionUnitObj);
                    this.positionUnitObj.value = event.rowData.position_unit;
                    this.positionUnitObj.appendTo(this.positionUnitElem);
                }
            },

            MPUElem: undefined,
            MPUObj: undefined,

            minutesPerUnitParams: {
                create: () => {
                    this.MPUElem = document.createElement("input");
                    return this.MPUElem;
                },
                read: () => {
                    return this.MPUObj.value;
                },
                destroy: () => {
                    this.MPUObj.destroy();
                },
                write: (event) => {
                    console.log("write");
                    console.log(event);
                    this.MPUObj = new NumericTextBox({
                        floatLabelType: "Never",
                        placeholder: "Minuta po jedinici",
                    });

                    this.MPUObj.value = event.rowData.minutes_per_unit
                    this.MPUObj.appendTo(this.MPUElem);
                },
                params: {decimals: 0 }

            },

            rowDropped: false,

            allPositions: {},

            editForm: {
                insertMethod: 'after', 
                insertLocation:0, 
                subGroup:true, 
                name: ""    
            },
            workPositionsTree: [],
            workPosionsCopy: [],
            freeId: -1,

            selectedPositionGroup: undefined,

            selectedPosition: null,
            positionUnitId: null,
            position_unit: null,
            modalImportWorkPositions: null,
        }
    },

    computed: {
        ...mapState([
            'position_units',
            'positionUnits',
            'workPositionsStatus',
            'workOrder',
            'user'
        ]),

        workOrderID() {
            return Number(this.$route.params.woId);
        },

        toolbar() {
            if (this.editMode) return this.toolbarEdit

            return null;
        },

        commands() {
            if (this.editMode) return this.commandsEdit

            return null;
        },


        getTreeList() {
        console.log("recalculate");
        let result = [];
        let explore;
        // if (this.selectedGroup == null || this.selectedGroup == -1){
            explore = UtilService.deepClone(this.workPosionsCopy)
        // } else {
        //   explore = UtilService.deepClone(this.workPosionsCopy[this.selectedGroup].children)
        // }
        
        let current;
        while(explore.length != 0){
            current = explore[0];
            explore.shift();

            explore.push.apply(explore, current.children);

            result.push({id: current.id, name: current.name, depth: current.depth, fullIndex: current.fullIndex});

        }

        if (result.length == 0) {
            return this.getDefaultEmptyGroupList();
        }

        return result.sort((a, b) => (a.fullIndex > b.fullIndex) ? 1 : -1);
        },


        getGroupsList() {
            let result = [];
            var i;
            for (i = 0; i < this.workPosionsCopy.length; i++){
                result.push(this.workPosionsCopy[i]);
            }

        return result;
        },

        positionUnitsList(){
            return Object.values(this.position_units);
        }

    },

    methods: {
        recalculateFullIndex(obj, prefix, depth=0, parent=null, root_id=null) {
            for(let i = 0 ; i < obj.length ; i++) {
                obj[i].fullIndex = prefix + (i+1);
                obj[i].depth = depth;
                obj[i].parentNode = parent;
                obj[i].order_index = i;
                obj[i].root_position_id = root_id;
                let sub_root_id = root_id;
                if (obj[i].parent==null) 
                    sub_root_id = obj[i].id;

                if (obj[i].position_unit_id) {
                    obj[i].position_unit = this.position_units[obj[i].position_unit_id];
                }
                if (obj[i].id >= this.freeId) this.freeId = obj[i].id  + 1;
                if (obj[i].children?.length > 0){
                this.recalculateFullIndex(obj[i].children, prefix+(i+1)+".", depth+1, obj[i], sub_root_id);
                }
            }

        },

        positionUnitsAccessor(field, data) {
            // if (field == "key") return this.translateTransportType(data.key);
            let group = this.positionUnits.find(x=>x.id == data['position_unit_id'])
            return group?.name || "";
        },

        recordClick() {
            console.log(this.$refs.positionsTree);
        },

        customiseCell(args) {
            if (args?.column?.field?.startsWith('name') == true) {
                let margin = Number(args.data.level);

                margin = 1.6 + margin * 0.9

                args.cell.style.paddingLeft = margin + "em";
                if (args.data.level > 0) {
                    args.cell.classList.add('treePaddingBefore');
                }
            }

            if (args.data.deleted) {
                args.cell.classList.add('deleted-row');
            }
        },

        actionCompleteEvent(event) {
            console.log(event);
            console.log(this.$refs.positionsTree);
            if (event.requestType == "save") {
                this.recalculateTreeData()
            } else if (event.requestType == "refresh" && this.rowDropped === true) {
                this.rowDropped = false
                this.recalculateTreeData();
            } else if (event.requestType == "beginEdit") {
                this.beignEditEvent(event)
            } else if (event.requestType == "add") {
                this.beignEditEvent(event)
            } else if (event.requestType == "edit") {
                this.rowEditEvent(event)
            }
        },


        recalculateTreeData(data) {
            if (data) {
                this.workPositionsTree = data
            } else {
                this.workPositionsTree = this.$refs.positionsTree.ej2Instances.dataModule.hierarchyData
            }
            this.recursiveFixIdList(this.workPositionsTree);
            UtilService.recalculatePositionFullIndex(this.workPositionsTree);
            UtilService.removeFieldRecursive(this.workPositionsTree, 'parentNode')

            this.syncDataSource();
            this.locationsTotal = UtilService.locationsTotal;

            this.$refs.positionsTree.ej2Instances.hideSpinner();


        },

        syncDataSource() {
            this.workPositionsTree = [...this.workPositionsTree]
        },


        async rowDrop() {
            this.rowDropped = true;
        },

        actionFailure(event) {
            console.log(event);
        },


        addRowBelow(event) {
            let index = this.commandClickGetIndex(event)
            this.treeGridAddRow(index, "Below")
        },

        addRowChild(event) {
            let index = this.commandClickGetIndex(event)
            this.treeGridAddRow(index, "Child")
        },

        commandClickGetIndex(event) {
            let target = event.target
            if (target.tagName != "BUTTON") target = target.parentElement

            let index = target.id.split("_")[2];
            return Number(index)
        },

        treeGridAddRow(index, position="Below") {
            this.$refs.positionsTree.ej2Instances.selectRow(index);
            this.$refs.positionsTree.ej2Instances.editSettings.newRowPosition = position
            this.$refs.positionsTree.ej2Instances.addRecord();

        },


        getFormPositionUnit(){
            if (this.editForm.subGroup)
                return null;

            return this.position_unit_id;
        },

        getFormPositionName(){
            return this.editForm.name;
        },

        getFormMinutesPerUnit() {
            if (this.editForm.subGroup)
                return null;

            return this.editForm.minutes_per_unit;
        },

        getDefaultEmptyGroupList() {
        return [{
            depth: 0,
            fullIndex: "",
            id: -1,
            name: "Na početak"
        }]
        },
        

        nestableChanged() {
        this.recalculateFullIndex(this.workPosionsCopy, "");
        },

        enableEditMode() {
            this.editMode = true;

            this.$refs.positionsTree.ej2Instances.editSettings.allowEditing = true;
            this.$refs.positionsTree.ej2Instances.allowRowDragAndDrop = true;
            this.$store.dispatch('enableClosePrompt', {message: "Da li želite da izađete. Radne lokacije NISU sačuvane"});

        },

        addPositionGroupParam() {
            let explore = [...this.workPositionsTree];
            let current;
            while(explore.length != 0){
                current = explore[0];
                explore.shift();
                explore.push.apply(explore, current["children"]);
                
                delete current.parentNode
            }
        },
        
        saveEdit() {
            this.addPositionGroupParam();
            this.$store.dispatch('updateWorkPositions', {workOrderId: this.workOrderID, workPositions: this.workPositionsTree});
            this.discartEdit();
        },

        
        discartEdit() {
            this.recalculateFullIndex(this.workPosionsCopy, "");
            this.editMode = false;
            this.$refs.positionsTree.ej2Instances.editSettings.allowEditing = false;
            this.$refs.positionsTree.ej2Instances.allowRowDragAndDrop = false;

            this.$store.dispatch('disableClosePrompt');
        },


        haschildren(obj) {
        if (obj.children?.length > 0){
            return true;
        }
        return false;
        },

        editTypes(event) {
        event.preventDefault();
        event.stopPropagation();
        },

        getFreeId() {
            return this.freeId++;
        },


        recursiveFixId(obj) {
            obj.id = this.getFreeId();
            obj.fake_id = true;
            obj.children.forEach(element => {
                this.recursiveFixId(element);
            });
        },

        recursiveFixIdList(objList) {
            objList.forEach(obj => {
                if (!obj.id) {
                    obj.id = UtilService.getFreePositionId();
                    obj.fake_id = true;
                }
                if (obj.children) this.recursiveFixIdList(obj.children);
            });
        },


        editPosition() {
            this.selectedPosition.name = this.getFormPositionName();
            this.selectedPosition.minutes_per_unit = this.getFormMinutesPerUnit();
            this.selectedPosition.position_unit_id = this.getFormPositionUnit();

            // Location will stay same
            if (this.editForm.insertLocation == this.selectedPosition.id) {
                this.recalculateFullIndex(this.workPosionsCopy, "");
                this.selectedPosition = null;
                return;
            }
            alert(this.selectedPosition.name)

            if (UtilService.findObjectInTree(this.selectedPosition, this.editForm.insertLocation )){
                alert("Roditeljski element se ne može premesetiti u unutrašnji");
                return;
            }

            let tmpRoot = {children:this.workPosionsCopy};
            tmpRoot.children.forEach(element => {
                element.parentNode = tmpRoot;
            });

            UtilService.removeObject(tmpRoot, this.selectedPosition.id, true);
            if (this.editForm.insertMethod === 'after') this.insertAfter(tmpRoot, this.selectedPosition);
            if (this.editForm.insertMethod === 'before') this.insertBefore(tmpRoot, this.selectedPosition);
            if (this.editForm.insertMethod === 'inside') this.insertInside(tmpRoot, this.selectedPosition);

            this.recalculateFullIndex(this.workPosionsCopy, "");
            this.selectedPosition = null;
        },

        removePosition() {
        // let tmpRoot = {children:this.workPosionsCopy};
        // tmpRoot.children.forEach(element => {
        //     element.parentNode = tmpRoot;
        // });


        this.selectedPosition.deleted
        if (this.selectedPosition.deleted == true){
            UtilService.restoreRemovedObject(this.selectedPosition);
            this.selectedPosition = null;
            return;
        }      

        let deleted = UtilService.removeObjectLog(this.selectedPosition);

        if (deleted){
            this.selectedPosition = null;
            return;
        }

        // TODO: Create better prompt
        let force = confirm("BRIŠE SE RODITELJSKO STABLO SA ELEMENTIMA?");

        if (force) {
            UtilService.removeObjectLog(this.selectedPosition, true);
            this.selectedPosition = null;
        }
        },


        clonePositionItems() {
        let newObj = UtilService.deepClone(this.selectedPosition);
        // TODO fix ids
        this.recursiveFixId(newObj);
        newObj.name = this.getFormPositionName();
        newObj.minutes_per_unit = this.getFormMinutesPerUnit();
        newObj.position_unit_id = this.getFormPositionUnit();


        let tmpRoot = {children:this.workPosionsCopy};
        tmpRoot.children.forEach(element => {
            element.parentNode = tmpRoot;
        });


        if (this.editForm.insertMethod === 'after') this.insertAfter(tmpRoot, newObj);
        if (this.editForm.insertMethod === 'before') this.insertBefore(tmpRoot, newObj);
        if (this.editForm.insertMethod === 'inside') this.insertInside(tmpRoot, newObj);


        this.recalculateFullIndex(this.workPosionsCopy, "");
        },

        async openTypesModal() {
            this.$refs.modalTypes.loadModalData();
        },

        async loadPositionUnits() {
            await this.$store.dispatch('loadPositionUnitsIfUndefined');
            this.convertPositionUnitsDropDown();
        },

        convertPositionUnitsDropDown() {
            let positionUnitsData = []

            // positionUnitsData.push({positionUnitId: null, positionUnitName: "Dodaj novi"})
            positionUnitsData.push({positionUnitId: null, positionUnitName: "Grupa više poslova"})

            this.positionUnits.forEach(unit => {

                let unitObj = {
                    positionUnitName: unit.name,
                    positionUnitId: unit.id,
                    positionUnitObj: unit
                }
                positionUnitsData.push(unitObj);

            })

            this.positionUnitsData = positionUnitsData;
        },

        async loadPositions() {
            let data = {
                workOrder: this.workOrderID
            }



            let result = await WorkPositionService.getWorkPositions(data);
            let workPositions = result.data

            let {data: workPositionsTree} = UtilWorkOrder.prepareWorkPositionsForTree(workPositions)
            this.workPositionsTree = workPositionsTree
            this.workPositionsTree


        },

        async loadData() {
            this.loadPositionUnits();
            this.loadPositions();
        },

        positionUnitChanged() {
            this.position_unit = this.position_units[this.position_unit_id];

            if (this.getFormMinutesPerUnit())
                this.editForm.minutes_per_unit = this.getFormMinutesPerUnit();
            else
                this.editForm.minutes_per_unit = this.position_unit.minutes_per_unit;

        },

        getWorkablePositionName(item) {
            if (!item.position_unit) return item.name;

            var name = item.position_unit.name
            if (item.name) name += ' (' + item.name + ')';
            return name;
        },

        setDeleteRows(rows, state) {
            this.workPositionsTree = this.$refs.positionsTree.ej2Instances.dataModule.hierarchyData
            UtilWorkOrder.setDeleteRows(this.workPositionsTree, rows, state)

            this.workPositionsTree = [...this.workPositionsTree]
            this.$refs.positionsTree.ej2Instances.refresh();
        },

        toolbarClickHandler(args) {
            this.$refs.positionsTree.ej2Instances.editSettings.newRowPosition = "Below"

            if (args.item.id === "deleteLocation"){
                let selected = (this.$refs.positionsTree.ej2Instances.getSelectedRecords());
                    if (selected[0].deleted) this.setDeleteRows(selected, false)
                    else this.setDeleteRows(selected, true)
            } else if (args.item.id === "importPositions") {
                this.openImportWorkPositionsModal();
            }
        },


        changedPositionGroup() {
            this.loadPositions();
        },

        beignEditEvent(event) {
            event.row.querySelectorAll(".c-column-hide-edit").forEach((el) => {
                el.classList.add('e-hide');
            });
        },

        rowEditEvent(event) {
            event.row.querySelectorAll(".c-column-hide-edit").forEach((el) => {
                el.classList.remove('e-hide');
            });
        },

        importWorkPositions(importedWorkPositions) {
            console.log(importedWorkPositions);

            importedWorkPositions = JSON.parse(JSON.stringify(importedWorkPositions))

            let explore = [...importedWorkPositions];
            let current;
            while(explore.length != 0){
                current = explore[0];
                explore.shift();
                explore.push.apply(explore, current["children"]);
                current.id = -1
                current.fake_id = true
            }

            this.workPositionsTree = [...this.workPositionsTree, ...importedWorkPositions]
            this.recalculateTreeData(this.workPositionsTree)
        },

        openImportWorkPositionsModal() {
            this.modalImportWorkPositions.show();
        },

        addTypeFunc(typeName) {
            return function(workPosition) {
                if (!workPosition.types) workPosition.types = {}
                workPosition.types[typeName] = {"value": 0}
                
            }
        },

        addType() {
            let typeName = prompt("Naziv tipa")
            UtilService.recursiveDoFunction(this.workPositionsTree, this.addTypeFunc(typeName))
            let newRow = {'field':'types.' + typeName + '.value', 'headerText':typeName}
            this.gridColumns.splice(-2,0,newRow)
            console.log(this.$refs.positionsTree.ej2Instances)
            // this.$refs.positionsTree.ej2Instances.refresh();
        },
    },


    created() {
        this.loadData();
    },

    mounted() {
        this.modalImportWorkPositions = new Modal(document.getElementById('modalImportWorkPositions'));
    }
}
</script>

<style scoped>

.container {
    min-height: 74vh;
}

.edit-commands {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    max-height: 50vh;
    /* overflow-x: hidden; */
    overflow-y: auto;
    box-shadow: #00000026 0 6px 20px;
    margin: 0 -0.75rem;
    padding: 0 0.75rem;
    resize: vertical;
    
}


.title-header{
    padding: 1em 0;
    background: #299be4;
    color: #fff;
    margin: 10px 0px 0;
}
.title-header h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}
.title-header .btn-header {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    /* margin-left: 10px; */
}
.title-header .btn-header:hover, .title-header .btn-header:focus {
    color: #566787;
    background: #f2f2f2;
}

.title-header .btn-header .btn-title {
    margin-top: 20px;
}

.sticky-acordion {
    position: sticky;
    top: 0px;
    z-index: 5;
}

.position_unitselect .vs__selected-options {
    padding: 10px;
}

  .position-copy {
      margin-left: 10px;
  }

  /* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>

<style>

.positions-table table .deleted-row {
    background: #ff4949;
}
.positions-table table tr:hover .deleted-row {
    background: #e93e3e !important;
}
</style>