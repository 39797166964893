<template>
    <div class="modal fade" :id="inputId" tabindex="-1" data-bs-backdrop="static" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="inputId + 'Label'">
                    Izmeni tipove
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    
                    
                    <select class="form-select form-select-lg mb-3" v-model="selectedGroup" aria-label=".form-select-lg groupselect" @change="changePositionSelect">
                    <option value="-1" selected disabled>Izaberite grupu</option>
                    <option v-for="position in value" :key="position.id" :value="position.id">{{position.name}} {{position.deleted?" &lt;&lt; OBRISAN >>":""}}</option>
                    </select>

                    <div v-if="selectedType" class="input-group mb-4">
                        <input type="text" class="form-control" id="editTypeInput" aria-label="Tip lokacije" aria-describedby="add-type-btn" :value="selectedType.name">
                        <button class="btn btn-outline-primary" type="button" id="edit-type" v-on:click="editSelectedType()">Izmeni</button>
                        <button class="btn btn-outline-danger" type="button" id="delete-type" v-on:click="deleteSelectedType()">{{selectedType && selectedType.deleted?"Aktiviraj tip":"Obirsi"}}</button>
                        <button class="btn btn-outline-secondary" type="button" id="delete-type" v-on:click="clearColumnSelection()"><i class="fas fa-times" /></button>
                    </div>


                    <div class="table-responsive" id="norm-table">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="w-sm" scope="col">ID</th>
                                <th class="w-sm" scope="col">Index</th>
                                <th class="w-lg sticky-col" scope="col">Pozicija</th>
                                <th class="w-md" scope="col">Jed. Mere</th>
                                <th class="w-md" scope="col">Jedinica</th>
                                <th class="w-md" scope="col">Vreme po jed.</th>
                                <th class="w-md type-column" v-on:click="selectColumn(type.id)" v-bind:class="{'selected': isTypeSelected(type)}" v-for="type in getTypes()" :key="type.id" scope="col">{{type.name}} <span v-if="type.deleted" class="ms-2 badge bg-danger">Obrisan</span></th>
                                <th class="w-lg" v-if="selectedGroup > 0">
                                    <form v-on:submit.prevent="test" class="input-group">
                                        <input :disabled="isFieldDisabled" type="text" class="form-control" v-model="newTypeName" placeholder="Novi tip" aria-label="Novi tip" aria-describedby="add-type-btn">
                                        <button :disabled="isFieldDisabled" v-on:click="addType(newTypeName)" class="btn btn-outline-secondary" type="submit" id="add-type-btn"><i class="fas fa-plus-circle fa-lg" /></button>
                                    </form>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="position in getTreeList">
                                <tr :key="position.id">
                                    <td scope="row">{{position.id}}</td>
                                    <td scope="row">{{position.fullIndex}}</td>
                                    <td v-if="position.position_unit" :class="{'sticky-col': true, 'fw-bold':position.position_unit}">{{ position.position_unit.name }} <span v-show="position.name"> ({{position.name}})</span></td>
                                    <td v-else :class="{'sticky-col': true, 'fw-bold':position.position_unit}">{{position.name}}</td>
                                    <template v-if="position.position_unit">
                                    <td>{{position.position_unit.norm_unit}}</td>
                                    <td>{{position.position_unit.norm_quantity}}</td>
                                    <td>{{position.position_unit.minutes_per_unit}}min</td>

                                    <td v-for="type in getTypes()" :key="type.id">
                                        <input v-if="type.normatives[position.id] != undefined" :disabled="isFieldDisabled || type.deleted" type="number" class="form-control" id="floatingInputValue" placeholder="Nije normirano" min="0" v-model="type.normatives[position.id].value">
                                    </td>     
                                    </template>            
                                    
                                </tr>
                            </template>
                            </tbody>
                        </table>  
                    </div>



                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Poništi izmene</button>
                    <button type="button" class="btn btn-primary" v-on:click="updateTypes()">
                        <div v-if="locationTypesStatus == 'SENDING'" class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                        </div>
                        Sačuvaj
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import UtilService from '@/service/UtilService';
import {mapState} from 'vuex'
import Vue from 'vue'

export default {
    components: {
    },

    props: {
        value: Array,
        focus: Object,
        workOrderID: Number,
        inputId: {
            type: String,
            default: "",
        }
        
    },


    data() {
        return {
            selectedGroup: -1,
            selectedType: null,
            newTypeName: "",
            locationTypesCopy: {},
            
        }
    },

    computed: {
        ...mapState([
            'locationTypes',
            'locationTypesStatus',
            'locationTypesMessage',
        ]),
        getTreeList() {
            return this.getPositionsTreeList(this.selectedGroup);
        },

        // getTypes() {
        //     return this.types[this.selectedGroup];
        // },

        selectedGroupObj() {
            let res = this.value.find(pg => pg.id == this.selectedGroup);
            if (!res) return [];
            return res;
            // return this.value[this.selectedGroup];
        },

        isFieldDisabled() {
            return this.selectedGroupObj?.deleted;
        }

    },

    // watch: {
    //     // When work order is selected
    //     'locationTypes': function() {
    //         this.cloneLocationTypes();
    //         this.fixLocationMissingNormative();

    //     },

    //     'selectedGroup': function() {
    //         console.log("Change selection");
    //         this.fixLocationMissingNormative();
    //     }
    // },

    methods: {
        getPositionsTreeList(positionGroupId) {
            this.clearColumnSelection();

            let result = [];
            let explore;

            let positionGroup = this.value.find(pg => pg.id == positionGroupId);

            if (!positionGroup){
                return [];
            } else {
                explore = UtilService.deepClone(positionGroup.children);
            }
            
            let current;
            while(explore.length != 0){
                current = explore[0];
                explore.shift();

                explore.push.apply(explore, current.children);

                result.push({id: current.id, name: current.name, depth: current.depth, fullIndex: current.fullIndex, position_unit:current.position_unit ,deleted: current.deleted});

            }

            if (result.length == 0) return [];


            return result.sort((a, b) => UtilService.fullIndexCompare(a.fullIndex, b.fullIndex));
        },
        
        getTypes() {
            if (!this.locationTypesCopy || Object.keys(this.locationTypesCopy).length === 0 || this.selectedGroup <= 0) return;
            if (!this.locationTypesCopy[this.selectedGroup]) return;


            return this.locationTypesCopy[this.selectedGroup];
        },
        selectColumn(e) {
            this.locationTypesCopy[this.selectedGroup].forEach(element => {
                if (element.id === e) {
                    this.selectedType = element;
                    return;
                }
            });
        },

        clearColumnSelection() {
            this.selectedType = null;
        },

        isTypeSelected(type) {
            if (type.id === this.selectedType?.id){
                return true;
            }

            return false;
        },

        addType(name) {
            if (this.isFieldDisabled) return;

            name = name.trim();
            if (this.locationTypesCopy[this.selectedGroup] == undefined) this.locationTypesCopy[this.selectedGroup] = [];
            if (this.locationTypesCopy[this.selectedGroup].find(element => element.name.trim() == name)) {
                alert("Već postoji tip sa tim nazivom");
                return;
            }
            this.newTypeName = "";

            let newFakeId = this.getNewTypeId()

            this.locationTypesCopy[this.selectedGroup].push(
                {
                    'id': newFakeId, 
                    'fake_id': true,
                    'name': name,
                    'position_group_id': this.selectedGroup,
                    'normatives': this.createEmptyNormativesObject(null) 
                }     
            );
            let ele = document.getElementById('norm-table');
            ele.scrollLeft = ele.offsetWidth;
        },

        createEmptyNormativesObject(typeId) {
            let normatives = {};

            this.getTreeList.forEach(element => {
                if (element.position_unit) {
                    let norm = {
                        id: null,
                        value: null,
                        type_id: typeId,
                        work_position_id: element.id,
                        deleted: false,
                    }

                    normatives[element.id] = norm;
                }

            });
            return normatives;
        },

        editSelectedType() {
            this.selectedType.name = $('#editTypeInput').val();
        },

        deleteSelectedType() {

            if (this.selectedType?.deleted) {
                this.selectedType.deleted = false;
                this.clearColumnSelection();
                return;
            }
            if (confirm("Da li ste sigurni da želite da obrišete tip " + this.selectedType.name) == false) return;


            if (this.selectedType.fake_id)
                this.hardDeleteType(this.selectedType);
            else
                this.softDeleteType(this.selectedType);

            this.clearColumnSelection();


        },

        hardDeleteType(type) {
            let grTypes = this.locationTypesCopy[this.selectedGroup];
            let index = grTypes.indexOf(type);
            if (index > -1) {
                grTypes.splice(index, 1);
            }
            return;
        },

        softDeleteType(type) {
            type.deleted = true;
            return;
        },


        getNewTypeId() {
            let id = 0;
            this.locationTypesCopy[this.selectedGroup].forEach(element => {
                if (element.id >= id){
                    id = element.id + 1;
                }
            });
            return id;
        },

        // activateDragMove() {
        //     const ele = document.getElementById('norm-table');
        //     ele.style.cursor = 'grab';

        //     let pos = { top: 0, left: 0, x: 0, y: 0 };

        //     const mouseDownHandler = function(e) {
        //         ele.style.cursor = 'grabbing';
        //         ele.style.userSelect = 'none';

        //         pos = {
        //             left: ele.scrollLeft,
        //             top: ele.scrollTop,
        //             // Get the current mouse position
        //             x: e.clientX,
        //             y: e.clientY,
        //         };

        //         document.addEventListener('mousemove', mouseMoveHandler);
        //         document.addEventListener('mouseup', mouseUpHandler);
        //     };

        //     const mouseMoveHandler = function(e) {
        //         // How far the mouse has been moved
        //         const dx = e.clientX - pos.x;
        //         const dy = e.clientY - pos.y;

        //         // Scroll the element
        //         ele.scrollTop = pos.top - dy;
        //         ele.scrollLeft = pos.left - dx;
        //     };

        //     const mouseUpHandler = function() {
        //         ele.style.cursor = 'grab';
        //         ele.style.removeProperty('user-select');

        //         document.removeEventListener('mousemove', mouseMoveHandler);
        //         document.removeEventListener('mouseup', mouseUpHandler);
        //     };

        //     // Attach the handler
        //     ele.addEventListener('mousedown', mouseDownHandler);
        // },
        async loadModalData() {
            await this.$store.dispatch('loadWorkOrderTypes', {workOrderId: this.workOrderID});
            this.cloneLocationTypes();


        },


        test(event){
            console.log(event);
        },

        updateTypes() {
            let data = UtilService.stringifyRecursiveObject(this.locationTypesCopy);
            this.$store.dispatch('updateWorkOrderTypes', {workOrderId: this.workOrderID, types: JSON.parse(data)}).then(() => {
                this.cloneLocationTypes();
            });

        },

        cloneLocationTypes() {
            this.locationTypesCopy = UtilService.deepClone(this.locationTypes);
        },

        fixLocationMissingNormative(){
            console.log("FixingLocationMissingNormative");
            if (!this.locationTypesCopy || Object.keys(this.locationTypesCopy).length === 0) return;

            Object.values(this.locationTypesCopy).forEach(types => {
                types.forEach(element => {
                    if (element.position_group_id != this.selectedGroup) return; 

                    if (element.normatives && Object.keys(element.normatives).length === 0){
                        Vue.set(element, 'normatives', this.createEmptyNormativesObject(element.id))
                        // element.normatives = this.createEmptyNormativesObject(element.id);
                    } else {
                        console.log();
                        this.checkForMissingNorm(element.normatives, element.id, element.position_group_id);
                    }
                });
            });
        },

        changePositionSelect() {
            this.fixLocationMissingNormative();
        },
    
        checkForMissingNorm(normatives, typeId, positionGroupId){
            this.getPositionsTreeList(positionGroupId).forEach(element => {
                if (!normatives[element.id]){
                    normatives[element.id] = {
                        id: null,
                        value: undefined,
                        type_id: typeId,
                        work_position_id: element.id,
                        deleted: false,
                    }
                }
            });
        }

    },

    mounted() {
        UtilService.activateDragMove('norm-table')
        // this.activateDragMove();
    }

}

</script>

<style scoped>

.table > thead:first-child > tr:first-child > .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;}

.table > tbody > tr > .sticky-col {
    position: sticky;
    left: 0;
    background: #fff;
    min-width: 200px;
    border-right: 1px solid rgb(218, 218, 218);
}

.table > tbody > tr > .sticky-col::after {
    content: '';
    position: absolute;
    background: #dee2e6;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
}

.table > thead > tr .type-column{
    cursor: pointer;
}

.table > thead > tr .type-column:hover{
    background: rgb(231, 231, 231);
}

.table > thead > tr .type-column.selected{
    background: rgb(231, 231, 231);
}

.table tr .w-lg {
    min-width: 15em;
}

.table tr .w-md {
    min-width: 7em;
}

.table tr .w-sm {
    min-width: 4em;
}

</style>